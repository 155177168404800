































































import Vue from "vue";

export default Vue.extend({
  name: "Header",
  methods: {
    resetImpersonation(){
      this.$request
        .post(`${this.$store.state.APIurl}/beheer/portal-preparation/manage-accounts/reset-impersonation`)
        .then(() => {
          location.reload();
        })
        .catch((error: any) => {
          this.$data.error = error.response.data.detail;
        });
      }
    },
  computed: {
    impersonating() {
      let doc = this.$store.state.user.document
      if (doc == null){
        return false
      }
      return doc.user_id != doc.original_user_id
    },
    menuButtons(): {
      text: string;
      to: string;
      visible: boolean;
      enabled: boolean;
      current: boolean;
    }[] {
      return [
        {
          text: "Uploaden",
          to: "/uploaden",
          visible: true,
          enabled: this.$store.state.user.isAuthenticated,
          current: this.$route.name == "Uploaden",
        },
        // {
        //   text: "Verbeteren",
        //   to: "/verbeteren",

        //   enabled:
        //     this.$store.state.user.document &&
        //     this.$store.state.user.numFindings.total > 0,
        //   current: this.$route.name == "Verbeteren",
        // },
        {
          text: "Downloaden",
          to: "/downloaden",
          visible: true,
          enabled: this.$store.state.user.document.active,
          current: this.$route.name == "Downloaden",
        },
        {
          text: "Verwijderen",
          to: "/verwijderen",
          visible: true,
          enabled: this.$store.state.user.document.active,
          current: this.$route.name == "Verwijderen",
        },
        {
          text: "Vaststellen",
          to: "/vaststellen",
          visible: true,
          enabled: this.$store.state.user.document.active,
          current: this.$route.name == "Vaststellen",
        },
        {
          text: "KvK controle",
          to: "/kvk",
          visible: this.$store.state.user.contactPerson,
          enabled: this.$store.state.user.contactPerson,
          current: this.$route.name == "kvk",
        },
        {
          text: "Open Data controle",
          to: "/open-data",
          visible: this.$store.state.user.contactPerson,
          enabled: this.$store.state.user.contactPerson,
          current: this.$route.name == "OpenData",
        },
        {
          text: "Staffel controle",
          to: "/staffel",
          visible: this.$store.state.user.contactPerson,
          enabled: this.$store.state.user.contactPerson,
          current: this.$route.name == "Staffel",
        },
        {
          text: "Portaal beheer",
          to: "/beheer",
          visible: this.$store.state.user.admin,
          enabled: this.$store.state.user.isAuthenticated,
          current: this.$route.name == "Logout",
        },
        {
          text: "Uitloggen",
          to: "/logout",
          visible: true,
          enabled: this.$store.state.user.isAuthenticated,
          current: this.$route.name == "Logout",
        },
      ];
    },
  },
});
