





























































































































































































import Vue from "vue";
import { AxiosResponse } from "axios";
import content from "@/content.json";
import SelectOrganization from "@/components/SelectOrganization.vue";
import ProgressTracker from "@/components/lib/ProgressTracker.vue";
import BeheerTable from "@/components/lib/BeheerTable.vue";
import FindingsSummary from "@/components/FindingsSummary.vue";

export default Vue.extend({
  name: "upload-component",
  components: {
    SelectOrganization,
    ProgressTracker,
    BeheerTable,
    FindingsSummary,
  },
  data: () => ({
    page: content.pages.upload,
    links: content.links,
    files: [] as File[],
    organizationIds: [] as number[],
    orgsAreNew: false,
    loading: false,
    error: "",
    frontEndError: "",
    success: "",
    dialog: {
      overwrite_file: false,
      supplement_data: false,
    },
    aanleverStatusData: [],
    aanleverRemaining: 0,
    trackerName:''
  }),
  methods: {
    clickControleer() {
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.$store.state.user.token}`,
      };
      this.$request(
        `${this.$store.state.APIurl}/document/existing-onderdelen`,
        { headers }
      ).then((response: any) => {
        const existingOnderdelen = response.data;
        const existingOnderdelenIds = existingOnderdelen.map(
          (ond: any) => ond.id
        );
        // check if existingOnderdelenIds and organizationIds are mutually exclusive
        const intersection = existingOnderdelenIds.filter((newId: any) =>
          this.organizationIds.includes(newId)
        );
        this.orgsAreNew = intersection.length === 0;
        if (
          this.$store.state.user.document &&
          this.files.length > 0 &&
          !this.orgsAreNew
        ) {
          this.dialog["overwrite_file"] = true;
        } else {
          this.postFilesForValidation();
        }
      });
    },
    postFilesForValidation() {
      if (this.exceedFileSize(150)) {
        this.$data.loading = false;
        this.$data.frontEndError =
          "Bestand is te groot. Verklein het bestand tot max. 15Mb.<br>Verwijder overbodige sheets of verminder het aantal rijen";
        this.dialog["overwrite_file"] = true;
        return;
      }

      this.error = "";
      this.loading = true;
      this.trackerName = `spend-upload-validation-${Date.now()}`
      const formData = new FormData();
      for (let i = 0; i < this.$data.files.length; i++) {
        formData.append("documents", this.$data.files[i]);
      }
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.$store.state.user.token}`,
      };
      const url = `${this.$store.state.APIurl}/document/validatie-upload?selected_org_ids=${this.$data.organizationIds}&tracker_name=${this.trackerName}`;
      this.$request
        .post<FormData, AxiosResponse<any>>(url, formData, {
          headers: headers,
          // timeout: 2000
        })
        .then((response: any) => {
          const response_data: any = response.data;
          this.$store.dispatch("updateDocument", response_data.document);
          this.$store.dispatch("updateFindings", response_data.num_findings);
          this.$store.dispatch(
            "updateAutocorrections",
            response_data.autocorrections
          );
          this.$data.numberLeverancierMatches = this.countLeverancierMatched();
          this.$data.error = "";
          this.$data.frontEndError = false;

          // if (this.$data.numberLeverancierMatches > 0) {
          //   this.dialog['supplement_data'] = true
          // }

          this.success = response.data.message;
        })
        .catch((error: any) => {
          this.success = "";
          if (error.code == "ECONNABORTED") {
            this.error = content.common.timeout;
            this.frontEndError = this.error;
          } else {
            this.error = error.response.data.detail;
          }
        });
      this.$data.files = [];
      this.dialog["overwrite_file"] = false;
    },
    closeProgressTracker() {
      this.loading = false;
      this.error = "";
      this.success = "";
      this.fetchAanleverStatus();
    },
    supplementMatches() {
      let document_id = this.$store.state.user.document.id;
      this.$request
        .post<FormData, AxiosResponse<any>>(
          `${this.$store.state.APIurl}/document/supplement-leveranciers?document_id=${document_id}`
        )
        .then((response: any) => {
          console.log(response);
          this.$store.dispatch("updateFindings", response.data.num_findings);
        })
        .catch((error: any) => {
          console.log(error);
        });
      this.dialog["supplement_data"] = false;
    },
    clearFileInput() {
      this.files = [];
      (this.$refs.file as HTMLInputElement).value = "";
    },
    uploadFile(e: { target: { files: any } }) {
      this.files = e.target.files;
    },
    dragFile(e: { dataTransfer: { files: any } }) {
      this.files = e.dataTransfer.files;
      if (this.files.length > 1) {
        // only allow a single file
        this.files = [this.files[0]];
      }
    },
    clickDropZone() {
      document.getElementById("hidden-file-input")?.click();
    },
    countLeverancierMatched(): number {
      return this.$store.state.user.autocorrections.leverancier_matched;
    },
    exceedFileSize(max_mb: number): boolean {
      let max_size = max_mb * Math.pow(10.0, 6);

      return this.files[0].size > max_size;
    },
    fetchAanleverStatus() {
      this.$request(
        `${this.$store.state.APIurl}/document/aanlever-status`
      ).then((response: any) => {
        this.aanleverStatusData = response.data;
        let num = this.aanleverStatusData
          .map((a: any) => a.Geüpload)
          .filter(Boolean).length;
        this.aanleverRemaining = this.aanleverStatusData.length - num;
      });
    },
    downloadTemplate() {
      // this.loading = true;
      this.$request({
        url: `${this.$store.state.APIurl}/document/download-template`,
        method: "POST",
        responseType: "blob",
      })
        .then((response: any) => {
          let fileURL = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "Template spenduitvraag.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loading = false;
        })
        .catch((error: any) => {
          console.log(error);
          // this.$data.error = error
        });
    },
    downloadInstructions(){
      this.$request({
        url: `${this.$store.state.APIurl}/document/download-instructions`,
        method: "POST",
        responseType: "blob",
      })
        .then((response: any) => {
          let fileURL = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/pdf",
            })
          );
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "instructies spenduitvraag.pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(() => {
          this.success = "";
          this.error =
            "Het bestand kan niet worden geproduceerd. Neem contact op met de beheerder";
        })
    },
  },
  computed: {
    noFindingsNoAutocorrections() {
      const conditions = [
        this.$store.state.user.document,
        this.$store.state.user.numFindings.total === 0,
        this.$store.state.user.numFindings.autocorrection === 0,
      ];
      return conditions.every((condition) => condition);
    },
    hasCorrectionsOrFindings() {
      const conditions = [
        this.$store.state.user.document,
        this.$store.state.user.numFindings.total > 0 ||
          this.$store.state.user.numFindings.autocorrection > 0,
      ];
      return conditions.every((condition) => condition);
    },
    tooManyFindings() {
      const maxFindings = this.$store.state.user.numFindings.max;

      return (
        this.$store.state.user.numFindings.total +
        this.$store.state.user.numFindings.autocorrection >= maxFindings
      );
    },
  },
  mounted() {
    this.fetchAanleverStatus();
  },
});
