import Vue from "vue";
import vuetify from "./plugins/vuetify";
import { protectedAxiosClient, setApiUrl } from "@/dependencies";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import UploadComponent from "@/components/UploadComponent.vue";
import RegisterUserComponent from "@/components/user/RegisterUserComponent.vue";
import MigrateUserComponent from "@/components/user/MigrateUserComponent.vue";
import NpEditorModule from "@/components/NpEditorModule.vue";
import YearSelectorComponent from "@/components/YearSelectorComponent.vue";
import CleanupComponent from "@/components/CleanupComponent.vue";

Vue.config.productionTip = false;

Vue.component("upload-component", UploadComponent);
Vue.component("register-user", RegisterUserComponent);
Vue.component("migrate-user", MigrateUserComponent);
Vue.component("np-editor", NpEditorModule);
Vue.component("year-selector", YearSelectorComponent);
Vue.component("cleanup-component", CleanupComponent);

setApiUrl();

const vueInstance = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
});
vueInstance.$mount("#app");

const token = sessionStorage.getItem("jwt") || "{}";
Vue.prototype.$request = protectedAxiosClient(
  token,
  vueInstance.$store.state.APIurl
);
