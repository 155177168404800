import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import axios, { AxiosResponse } from "axios";
import store from "@/store/index";
import { User } from "@/types/user";
import { protectedAxiosClient } from "@/dependencies";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Welkom",
    component: () => import("@/components/WelkomComponent.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/components/LoginComponent.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("@/components/LogoutComponent.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/uploaden",
    name: "Uploaden",
    component: () => import("@/components/UploadComponent.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/verbeteren",
    name: "Verbeteren",
    component: () => import("@/components/VerbeterComponent.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/downloaden",
    name: "Downloaden",
    component: () => import("@/components/DownloadComponent.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/verwijderen",
    name: "Verwijderen",
    component: () => import("@/components/VerwijderComponent.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vaststellen",
    name: "Vaststellen",
    component: () => import("@/components/VaststelComponent.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/geen-toegang",
    name: "GeenToegang",
    component: () => import("@/components/GeenToegang.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/wachtwoord-wijzigen",
    name: "update-password",
    component: () => import("@/views/UpdatePassword.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/wachtwoord-vergeten",
    name: "reset-password",
    component: () => import("@/views/ResetPassword.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/regels",
    name: "Rules",
    component: () => import("../views/Rules.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bevindingen",
    name: "Findings",
    component: () => import("../views/Findings.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kvk",
    name: "Kvk",
    component: () => import("../views/Kvk.vue"),
    meta: {
      requiresAuth: true,
      contactPersonOnly: true,
    },
  },
  {
    path: "/open-data",
    name: "OpenData",
    component: () => import("../views/OpenData.vue"),
    meta: {
      requiresAuth: true,
      contactPersonOnly: true,
    },
  },
  {
    path: "/staffel",
    name: "Staffel",
    component: () => import("../views/Staffel.vue"),
    meta: {
      requiresAuth: true,
      contactPersonOnly: true,
    },
  },
  {
    path: "/beheer",
    name: "Management",
    component: () => import("../views/Management.vue"),
    meta: {
      requiresAuth: true,
      beheerderOnly: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _from, next) => {
  if (!store.state.user.isAuthenticated) {
    const token = sessionStorage.getItem("jwt") || "{}";
    Vue.prototype.$request = protectedAxiosClient(token, store.state.APIurl);
    if (token === "{}" || !store.state.user.isAuthenticated) {
      await RouterFunctions.runVerification(token);
    }
  }

  if (
    (to.matched.some((record) => record.meta.beheerderOnly) && (!store.state.user.admin)) ||
    (to.matched.some((record) => record.meta.contactPersonOnly) && (!store.state.user.contactPerson))
    ){
      console.log('User does not have the proper permissions')
      next({
        path: "/geen-toegang",
        params: { nextUrl: to.fullPath },
      });
  }

  else if (to.matched.some((record) => record.meta.requiresAuth) && (!store.state.user.isAuthenticated)) {
    console.log ('User is not logged in')
    next({
      path: "/",
      params: { nextUrl: to.fullPath },
    });
  }

  else {
      next();
    }
});

export const RouterFunctions = {
  runVerification: async (token: string): Promise<AxiosResponse> => {
    return axios
      .get(`${store.state.APIurl}/user/login/verifieer`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const response_data: User = response.data;
        store.commit("changeUserId", response_data.id);
        store.commit("changeUserToken", token);
        store.commit("changeUserEmail", response_data.email);
        store.commit("changeUserAdmin", response_data.admin);
        store.commit("changeDocument", response_data.document);
        store.commit("changeNumFindings", response_data.num_findings);
        store.commit("changeAutocorrections", response_data.autocorrections);
        store.commit("changeUserAuthenticated", true);
        store.commit("changeContactPerson", response_data.contact_person);
        return response;
      })
      .catch((error) => {
        sessionStorage.removeItem("jwt");
        store.commit("changeUserId", undefined);
        store.commit("changeUserToken", "");
        store.commit("changeUserEmail", "");
        store.commit("changeUserAdmin", false);
        store.commit("changeUserAuthenticated", false);
        store.commit("changeDocument", null);
        store.commit("changeNumFindings", {});
        store.commit("changeAutocorrections", []);
        store.commit("changeContactPerson", false);
        return error;
      });
  },
};

export default router;
