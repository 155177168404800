import axios from "axios";
import store from "@/store";

// eslint-disable-next-line
const protectedAxiosClient = (token: string | null, baseURL: string) => {
  return axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// eslint-disable-next-line
const setApiUrl = () => {
  if (process.env.NODE_ENV === "production") {
    store.commit("changeAPIurl", `${window.location.origin}/api`);
  }
};

export { protectedAxiosClient, setApiUrl };
