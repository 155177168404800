import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import nl from "vuetify/src/locale/nl";

Vue.use(Vuetify);

const spend_theme = {
  primary: "#01689b",
  secondary: "#b3d2e1",
  tertiary: "#d9e8f0",
  accent: "#82B1FF",
  error: "#FF5252",
  info: "#2196F3",
  success: "#4CAF50",
  warning: "#FFC107",
  headerTextColour: "#000000",
  headerHoverColour: "#FCF29A",
};

//  modify primary color based on namespace
if (window.location.host.includes('ictu-devops-tst') || window.location.host.startsWith('test.')) {
  spend_theme.primary = '#FF8300'
} else if (window.location.host.includes('ictu-devops-acc') || window.location.host.startsWith('acc.')) {
  spend_theme.primary = '#008000'
} else if (window.location.host.includes('localhost')) {
  spend_theme.primary = '#151515'
} else {
  spend_theme.primary = '#01689b'
}

export default new Vuetify({
  theme: {
    themes: {
      light: spend_theme,
    },
    options: { customProperties: true, cspNonce: "eQw4j9WgXcB" },
  },
  lang: {
    locales: { nl },
    current: "nl",
  },
});
