
















































import Vue from "vue";
import content from "@/content.json";

export default Vue.extend({
  name: "FindingsSummary",
  props: {
    autocorrectionsOnly: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    page: content.pages.upload,
    count_text: content.pages.aantal_bevindingen,
    autocorrections_text: content.pages.num_autocorrections,
  }),
  computed: {
    numberFindings(): Record<string, number> {
      return this.$store.state.user.numFindings;
    },
    autoCorrectionsText(): string {
      let text = this.numberFindings.autocorrection === 1 ? this.autocorrections_text.one : this.autocorrections_text.multi
      text = text.replace("NUMBER", this.numberFindings.autocorrection.toString())
      if (this.autocorrectionsOnly) {
        text = text.replace(" ook ", " ");
      }
      return text
    },
  },
  methods: {
    countFindings(): number {
      return this.$store.state.user.numFindings;
    },
  },
  created() {
    this.$data.numberFindings = this.countFindings()
  }
})
