




























import Vue from 'vue'

export default Vue.extend({
  name: 'ProgressLoading',
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
})
