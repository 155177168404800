import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      id: undefined,
      email: "",
      token: "",
      admin: false,
      isAuthenticated: false,
      document: null,
      numFindings: {
        total: 0,
        critical: 0,
        high: 0,
        warning: 0,
        autocorrection: 0,
        max: 0,
      },
      autocorrections: {},
      contactPerson: false,
    },
    selectedId: null,
    APIurl: "http://localhost:8000/api",
  },
  mutations: {
    changeUserId(state, payload) {
      state.user.id = payload;
    },
    changeUserEmail(state, payload) {
      state.user.email = payload;
    },
    changeUserToken(state, payload) {
      state.user.token = payload;
    },
    changeUserAdmin(state, payload) {
      state.user.admin = payload;
    },
    changeUserAuthenticated(state, payload) {
      state.user.isAuthenticated = payload;
    },
    changeAPIurl(state, payload) {
      state.APIurl = payload;
    },
    changeDocument(state, payload) {
      state.user.document = payload;
    },
    changeNumFindings(state, payload) {
      state.user.numFindings = payload;
    },
    changeAutocorrections(state, payload) {
      state.user.autocorrections = payload;
    },
    changeContactPerson(state, payload) {
      state.user.contactPerson = payload;
    },
    changeSelectedId(state, payload) {
      state.selectedId = payload;
    },
  },
  actions: {
    updateUserId({ commit }, payload) {
      commit("changeUserId", payload);
    },
    updateMaxFindings({ commit }, payload) {
      commit("changeMaxFindings", payload);
    },
    updateUserEmail({ commit }, payload) {
      commit("changeUserEmail", payload);
    },
    updateUserToken({ commit }, payload) {
      commit("changeUserToken", payload);
    },
    updateUserAdmin({ commit }, payload) {
      commit("changeUserAdmin", payload);
    },
    updateUserAuthenticated({ commit }, payload) {
      commit("changeUserAuthenticated", payload);
    },
    updateAPIurl({ commit }, payload) {
      commit("changeAPIurl", payload);
    },
    updateDocument({ commit }, payload) {
      commit("changeDocument", payload);
    },
    updateFindings({ commit }, payload) {
      commit("changeNumFindings", payload);
    },
    updateAutocorrections({ commit }, payload) {
      commit("changeAutocorrections", payload);
    },
    UpdatecontactPerson({ commit }, payload) {
      commit("changeContactPerson", payload);
    },
    updateSelectedId({ commit }, payload) {
      console.log("updateSelectedId", payload);
      commit("changeSelectedId", payload);
    },
  },
  modules: {},
});
