



































































import Vue from "vue";

export default Vue.extend({
  name: "cleanup-component",
  data: () => ({
    error: '' as string,
    success: '' as string,
    confirmation: undefined,
    disableBtn: false,
    url: window.location.host,
    loading: false
  }),
  methods: {
    submit(){
        console.log(this.confirmation )
        if (this.confirmation != this.url){
          this.error = 'onjuiste input'
          return
        }
        this.loading = true
        this.$request
          .post(`${this.$store.state.APIurl}/beheer/close-portal/close`)
          .then(() => {
              this.success = 'Het portaal wordt nu schoongemaakt. Dit kan een paar minuten duren';
              this.confirmation = undefined
              this.disableBtn = true
            })
          .catch((error: any) => {
            this.error = error.response.data.detail;
          })
          .finally(() => {
            this.loading = false;
          });
    }
  }
});
