




























































import Vue from "vue";
import {Departement} from "@/types/organization";

export default Vue.extend({
  name: "select-organization",
  props: {
    contactOrgsOnly: {
      type: Boolean,
      default: false,
    },
    aanleverOrgsOnly: {
      type: Boolean,
      default: false,
    },
    endpoint: {
      type: String,
      required: true,
    },
    forAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
    hint: {
      type: String,
      required: false,
      default: "",
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true,
    },
    departmentsOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    prefillUserId: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      organizationTree: [] as any[],
      flatOrganizations: [] as any[],
      selectedOrganizationIds: [] as number[],
      selectedDepartmentIds: [] as number[],
      selectedAbbreviations: [] as string[],
      previousSelection: [] as any[],
    };
  },

  methods: {
    /**
     * Check if the user has been assigned to more than one department
     * or if the user has been assigned to two onderdelen from different departments
     */
    hasOnlyOneDepartment(selectedIds: number[]) {
      let parentIds = this.flatOrganizations
        .filter((org) => selectedIds.includes(org.value))
        .map((org) => org.parentId);
      // remove nulls from parentIds
      parentIds = parentIds.filter((id) => id !== null);
      // Make unique and check if length is exactly 1
      return new Set(parentIds).size <= 1;
    },
    displayInput(data: any) {
      if (data.item.isDepartement && !this.departmentsOnly) {
        return "";
      } else if (this.forAdmin) {
        const departement = this.organizationTree.find(
          (org: any) => org.id == data.item.parentId
        );
        return departement
          ? data.item.text + " (" + departement.abbreviation + ") "
          : data.item.text;
      } else {
        return data.item.text;
      }
    },

    // Additional action after v-select default behavior
    onSelected() {
      if (this.forAdmin) {
        if (this.departmentsOnly) {
          this.$emit("selectOrganization", this.selectedOrganizationIds);
          return;
        }
        const organizationIds = [...this.selectedOrganizationIds];
        const currentOrgId = organizationIds.pop();
        let departement = this.organizationTree.find(
          (org: any) => org.id == currentOrgId
        );
        // When no new departement is found, it may have been already selected
        // and is currently being deselected.
        const missingDepartement = (org: any) => {
          let difference = this.selectedDepartmentIds.filter(
            (x) => !organizationIds.includes(x)
          );
          return difference[0] == org.id;
        };
        let departmentDeselected =
          this.organizationTree.find(missingDepartement);
        if (departement) {
          this.selectDepartment(departement, organizationIds);
        } else if (departmentDeselected) {
          // Place back the current org id, because it's not a departement
          currentOrgId && organizationIds.push(currentOrgId);
          this.cancelFullDepartement(organizationIds, departmentDeselected);
        } else {
          // Find the checkbox that's being toggled
          let difference = this.previousSelection.filter(
            (orgId) => !organizationIds.includes(orgId)
          );
          let parent = this.flatOrganizations.find(
            (org: any) => org.value == difference[0]
          );
          if (parent) {
            this.deselectParent(parent);
          }
        }
        let deptIds = this.selectedDepartmentIds;
        let onderdelenOnly = this.selectedOrganizationIds.filter(
          (onderdeel_id) => !deptIds.includes(onderdeel_id)
        );
        this.$emit(
          "selectOrganization",
          this.departmentsOnly ? deptIds : onderdelenOnly
        );
        this.previousSelection = this.selectedOrganizationIds;
      } else {
        this.$emit("selectOrganization", this.selectedOrganizationIds);
      } // Remember previous selection
    },

    selectDepartment(departement: Departement, organizationIds: any[]) {
      let childOrgIds = departement.child_orgs.map((org: any) => org.id);
      childOrgIds = childOrgIds.filter(
        (org: any) => !organizationIds.includes(org)
      );
      this.selectedOrganizationIds.push(...childOrgIds);
      this.selectedDepartmentIds.push(departement.id);
    },

    deselectParent(parent: any) {
      // Deselect only the parent organization itself (departement)
      let parentId = parent.parentId;
      this.selectedOrganizationIds = this.selectedOrganizationIds.filter(
        (org: any) => org != parentId
      );
      this.selectedDepartmentIds = this.selectedDepartmentIds.filter(
        (org: any) => org != parentId
      );
    },

    cancelFullDepartement(ids: Array<number>, departement: Departement) {
      this.selectedOrganizationIds = ids.filter(
        (org: any) =>
          !departement.child_orgs.map((org: any) => org.id).includes(org)
      );
      // Remove departement
      const index = this.selectedDepartmentIds.indexOf(departement.id);
      if (index !== -1) {
        this.selectedDepartmentIds.splice(index, 1);
      }
    },

    // Vuetify Select component only accepts flat arrays, so we need to flatten the tree
    flattenTreeForSelection(rawTree: Array<never>) {
      let processedTree: any = [];
      const getSelectionItems = (org: any) => {
        const isDepartement = org.org_type == "departement";
        processedTree.push({
          value: org.id,
          text: org.name,
          abbr: org.abbreviation,
          isDepartement,
          disabled: isDepartement && !this.forAdmin,
          parentId: org.parent_id,
        });
        if (this.departmentsOnly && isDepartement) {
          // remove child_orgs
          org.child_orgs = [];
        }
        org.child_orgs && org.child_orgs.forEach(getSelectionItems);
      };
      rawTree.forEach(getSelectionItems);
      return processedTree;
    },

    showChip(data: any) {
      return this.departmentsOnly || !data.item.isDepartement;
    },
  },

  mounted() {
    // Add organization tree to data from organization tree API
    this.$request
      .get(`${this.$store.state.APIurl}/organization/${this.endpoint}`)
      .then((response: any) => {
        this.organizationTree = response.data;
        this.organizationTree.sort((a, b) => a.id - b.id);
        this.organizationTree.sort((a, b) => a.parent_id - b.parent_id);

        // }
        this.flatOrganizations = this.flattenTreeForSelection(response.data);
        // If user is admin but selection is not in admin page
        // if (this.$store.state.user.admin && !this.forAdmin) {
        //   this.flatOrganizations.sort((a: any, b: any) => a.value - b.value)
        // }
      })
      .catch(() => {
        this.flatOrganizations = [
          { text: "fout bij laden van organisaties", disabled: true },
        ];
        this.$emit(
          "error",
          "Er is een fout opgetreden bij het laden van uw account gegevens. Herlaad de pagina of neem contact op met de beheerder"
        );
      });

    if (this.prefillUserId != null) {
      this.$request
        .get(`${this.$store.state.APIurl}/user/?user_id=${this.prefillUserId}`)
        .then((response: any) => {
          if (this.departmentsOnly || this.contactOrgsOnly) {
            this.selectedOrganizationIds = response.data.contact_org_ids;
          } else {
            this.selectedOrganizationIds = response.data.organization_ids;
            this.previousSelection = response.data.organization_ids;
          }
        })
        .catch(() => {
          this.$emit(
            "error",
            "Er is een fout opgetreden bij het laden van de account gegevens"
          );
        });
    }
  },
});
