var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"header-component"}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('div',{staticClass:"logo"},[_c('div',{staticClass:"wrapper"},[_c('img',{attrs:{"alt":"Rijksoverheid Logo","src":require('@/assets/logo-ro.svg')}})])])]),_c('v-app-bar',{attrs:{"color":"primary","flat":"","dark":"","height":"70px"}},[_c('v-toolbar-title',[_vm._v(" Aanleverportaal "),_c('i',[_vm._v("Spenddata")]),_vm._v(" Rijksoverheid ")]),_c('v-spacer'),(_vm.$store.state.user.isAuthenticated)?_c('v-menu',{attrs:{"bottom":"","nudge-bottom":"56px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","tile":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-menu")]),_vm._v(" Menu ")],1)]}}],null,false,722584466)},[_c('v-list',_vm._l((_vm.menuButtons),function(item,i){return _c(item.enabled && !item.current ? 'router-link' : 'span',{key:i,tag:"router-link",staticClass:"menu-item",attrs:{"to":item.to}},[(item.visible)?_c('v-list-item',[_c('v-list-item-title',{class:item.enabled
                  ? item.current
                    ? 'current-page'
                    : ''
                  : 'disabled-item'},[_vm._v(_vm._s(item.text))])],1):_vm._e()],1)}),1)],1):_vm._e()],1),(_vm.impersonating)?_c('v-alert',{staticClass:"ma-2 impersonating",attrs:{"color":"warning"}},[_vm._v(" U heeft op dit moment een ander account overgenomen "),_c('v-btn',{attrs:{"color":"primary ml-4"},on:{"click":_vm.resetImpersonation}},[_vm._v(" Terugdraaien ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }