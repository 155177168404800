export interface ProgressData {
  steps: Step[];
  pct: number;
  ratio: string;
}

export interface Step {
  step_name: string;
  description: string;
  step_status: StepStatus;
}

export enum StepStatus {
  PENDING,
  IN_PROGRESS,
  COMPLETED,
}
