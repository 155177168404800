








































































import Vue from "vue";
import { UserRetrieved } from "@/types/user";

export default Vue.extend({
  name: "MigrateUserComponent",
  props: {
    itemId: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    valid: false,
    error: '' as string,
    success: '' as string,
    detail: [],
    availableUsers: [] as UserRetrieved[],
    selectedUser: undefined as UserRetrieved | undefined,
    currentUser: undefined as UserRetrieved | undefined
  }),
  methods: {
    getUsers() {
      this.$request
        .get(`${this.$store.state.APIurl}/user/list`)
        .then((response: any) => {
          let userList = response.data
          this.currentUser = userList.find((item: UserRetrieved) => item.id === this.itemId);
          this.availableUsers = userList.filter((item: UserRetrieved) => item.id !== this.itemId);
        })
        .catch((error: any) => {
          this.detail = error.response.data.detail;
          this.error = error;
        });
    },
    submit(){
      const fromId = this.selectedUser?.id;
      if (fromId == undefined) {
        this.error = 'No target specified';
        return
      }
      this.$request
        .post(`${this.$store.state.APIurl}/beheer/portal-preparation/manage-accounts/migrate?from_id=${fromId}&target_id=${this.itemId}`)
        .then(() => {
            this.selectedUser = undefined
            this.success = 'De wijzigingen voor dit account zijn succesvol opgeslagen';
            this.error = '';
          })
        .catch((error: any) => {
          this.detail = error.response.data.detail;
          this.error = error;
        });
    }
  },
  mounted() {
    this.getUsers();
  },
});
