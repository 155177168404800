




































































import Vue from "vue";

export default Vue.extend({
  name: "year-selector",
  data: () => ({
    valid: false,
    error: undefined,
    success: '' as string,
    detail: [],
    selectedYear: undefined as number | undefined,
    availableYears:[
      2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
      2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039,
      2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049
    ]
    }),
  methods: {
    getCurrent() {
      this.$request
        .get(`${this.$store.state.APIurl}/beheer/portal-preparation/year`)
        .then((response: any) => {
          this.selectedYear = response.data
        })
        .catch((error: any) => {
          this.detail = error.response.data.detail;
          this.error = error;
        });
    },
    submit(){
        this.$request
          .post(`${this.$store.state.APIurl}/beheer/portal-preparation/year?new_year=${this.selectedYear}`)
          .then((response: any) => {
              this.success = 'De wijziging is succesvol opgeslagen';
            })
          .catch((error: any) => {
            this.detail = error.response.data.detail;
            this.error = error;
          });
    }
  },
  mounted() {
    this.getCurrent();
  },
});
