



































































































































































































































































































import Vue from "vue";
import { TableRow } from "@/types/management";
import {RouterFunctions} from "@/router/index"
import ProgressLoading from '@/components/lib/ProgressLoading.vue'
export default Vue.extend({
  name: "beheer-table",
  components: {ProgressLoading},
  props: {
    tableTitle: {
      type: String,
      required: false,
    },
    tableData: {
      required: true,
      type: Array as () => TableRow[],
    },
    tableEndpoint: {
      type: String,
      required: false,
    },
    labels: {
      type: Object as () => {
        [key: string]: string;
      },
      default: () => ({}),
      required: false,
    },
    enableCreateButton: {
      type: Boolean,
      default: false,
    },
    enableDeleteButton: {
      type: Boolean,
      default: false,
    },
    enableEditButton: {
      type: Boolean,
      default: false,
    },
    enableMigrateButton: {
      type: Boolean,
      default: false,
    },
    enableVaststelButton: {
      type: Boolean,
      default: false,
    },
    enableTerugzetButton: {
      type: Boolean,
      default: false,
    },
    enableForcedFixButton: {
      type: Boolean,
      default: false,
    },
    enableDownloadButton: {
      type: Boolean,
      default: false,
    },
    enableArchiveButton: {
      type: Boolean,
      default: false,
    },
    enableImpersonateButton: {
      type: Boolean,
      default: false,
    },
    formComponentsNames: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      labelColors: ["green darken-1", "indigo lighten-1", "orange darken-1"],
      showButtons: false,
      rowHovered: {},
      formDialog:{
        'show': false as boolean,
        'migrate-user': false as boolean,
        'register-user': false as boolean
      },
      clicked: false,
      itemId: undefined as number | undefined,
      confirm:{
        func: undefined as any,
        item:  undefined as any,
        msg: '' as string,
        show:  false as boolean,
        danger: false as boolean
      }

    };
  },
  methods: {
    askConfirmation(func: any, item: TableRow, msg: string, danger = false) {
      this.confirm['func'] = func
      this.confirm['item'] = item
      this.confirm['msg'] = msg
      this.confirm['show'] = true
      this.confirm['danger'] = danger
    },
    cancelConfirmation() {
      this.confirm['func'] = undefined
      this.confirm['item'] = undefined
      this.confirm['show'] = false
      this.confirm['msg'] = ''
    },
    confirmConfirmation() {
      this.confirm['func'](this.confirm['item'])
      this.cancelConfirmation()
    },
    getLabelsForHeader(header: any): string[] {
      return this.labelNames.filter((key) => this.labels[key] === header);
    },
    isLabelForItem(label: string, itemIdx: any) {
      return this.tableData[itemIdx][label];
    },
    editItem(item: any) {
      this.formDialog = {'show': true, 'register-user': true, 'migrate-user': false}
      this.itemId = item.ID;
    },
    createItem() {
      this.formDialog = {'show': true, 'register-user': true, 'migrate-user': false}
      this.itemId = undefined;
    },
    migrateItem(item: any) {
      this.formDialog = {'show': true, 'register-user': false, 'migrate-user': true}
      this.itemId = item.ID;
    },
    closeDialog() {
      this.formDialog = {'show': false, 'register-user': false, 'migrate-user': false}
    },
    deleteItem(item: any) {
      this.$request
        .delete(this.tableEndpoint + "/delete/" + item.ID)
        .then((response: any) => {
          this.$emit("success", response.data.message);
        })
        .catch((error: any) => {
          console.log(error.response.data.detail);
          this.$emit("error", error.response.data.detail);
        });
    },
    archiveItem(item: any) {
      this.$request
        .put(this.tableEndpoint + "/archive/" + item.ID, {})
        .then((response: any) => {
          this.$emit("success", response.data.message);
        })
        .catch((error: any) => {
          this.$emit("error", error.response.data.detail);
        });
    },
    itemVaststellen(item: any) {
      this.$request
        .put(this.tableEndpoint + "/fix/" + item.ID, {})
        .then((response: any) => {
          this.$emit("success", response.data.message);
        })
        .catch((error: any) => {
          this.$emit("error", error.response.data.detail);
        });
    },
    itemTerugzetten(item: any) {
      this.$request
        .put(this.tableEndpoint + "/revert/" + item.ID, {})
        .then((response: any) => {
          this.$emit("success", response.data.message);
        })
        .catch((error: any) => {
          this.$emit("error", error.response.data.detail);
        });
    },
    itemDownload(item: any) {
      this.loading = true;
      this.$request
        .get(this.tableEndpoint + "/download/" + item.ID, {
          responseType: "blob",
        })
        .then((response: any) => {
          let fileURL = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", item.Naam);
          document.body.appendChild(fileLink);
          fileLink.click();
          // this.$emit('success', response.data.message)
        })
        .catch((error: any) => {
          error.response.data.text().then((jsonData: any) => {
            console.log(JSON.parse(jsonData));
            this.$emit("error", JSON.parse(jsonData).detail);
          });
        })
        .finally(() => {
          this.loading = false;
        })
    },
    impersonateItem(item: any) {
      this.$request
        .post(this.tableEndpoint + "/impersonate/" + item.ID, {})
        .then((response: any) => {
          this.$emit("success", response.data.message);
          // trigger a reload of all (impersonated) user data
          const token = sessionStorage.getItem("jwt") || "{}";
          RouterFunctions.runVerification(token);
        })
        .catch((error: any) => {
          this.$emit("error", error.response.data.detail);
        });
    },
  },

  computed: {
    anyButtonEnabled() {
      const enabledButtons = [
        this.enableCreateButton,
        this.enableDeleteButton,
        this.enableEditButton,
        this.enableMigrateButton,
        this.enableVaststelButton,
        this.enableTerugzetButton,
        this.enableDownloadButton,
      ];
      // rewrite the above line more elegantly
      return enabledButtons.some((button) => button);
    },
    headers(): Array<Record<string, string>> {
      const headers =
        this.tableData.length > 0 ? Object.keys(this.tableData[0]) : [];
      return headers.map((header) => {
        return { text: header, value: header };
      });
    },
    headersForDisplay(): Array<Record<string, any>> {
      const headerNoLabels = this.headers.filter(
        (header) => !this.labelNames.includes(header.value)
      );
      if (!this.anyButtonEnabled) {
        return headerNoLabels;
      }
      headerNoLabels.push({ text: "", value: "actions", sortable: "" });
      return headerNoLabels;
    },
    labelNames(): string[] {
      if (!this.labels || Object.keys(this.labels).length === 0) {
        return [];
      }
      return Object.keys(this.labels);
    },
    tableDataForDisplay(): TableRow[] {
      if (!this.tableData || Object.keys(this.tableData).length === 0) {
        return [];
      }
      // Remove labels from table data
      return this.tableData.map((row) => {
        const newRow = { ...row };
        this.labelNames.forEach((label) => {
          delete newRow[label];
        });
        return newRow;
      });
    },
  },
});
