











































































import Vue from "vue";
import ProgressStep from "@/components/lib/progressStep.vue";

export default Vue.extend({
  name: "ProgressTracker",
  components: { ProgressStep },
  props: {
    trackerName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "Voortgang",
    },
    endpoint: {
      type: String,
      default: "progress",
    },
    error: {
      type: String,
      required: false,
    },
    success: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    trackerDescription: "Initialiseren...",
    totalUnits: 1,
    currentUnits: 0,
    completed: false,
    aborted: false,
    feedback_message: "",
    steps: [],
    pct: 0,
    ratio: "0/-",
    timer: undefined as number | undefined,
    timeOutError: false,
  }),
  methods: {
    updateProgressData() {
      this.$request(
        `${this.$store.state.APIurl}/${this.endpoint}/track?tracker_name=${this.trackerName}`
      )
        .then((response: any) => {
          if (Object.keys(response.data).length > 0) {
            const progressData = response.data;
            this.totalUnits = progressData.total_units;
            this.steps = progressData.steps;
            this.pct = progressData.pct;
            this.ratio = progressData.ratio;
            this.trackerDescription = progressData.description;
            this.completed = progressData.completed;
            this.aborted = progressData.aborted;
            this.feedback_message = progressData.feedback_message;
            if (this.completed) {
              clearInterval(this.timer);
              this.timer = undefined;
            }
          }
        })
        .catch((error: any) => {
          if (error.code == "ECONNABORTED") {
            this.$emit("timeout-error");
          } else {
            this.$emit("unhandled-error");
          }
        });
    },
    startPolling: function () {
      this.updateProgressData()
      this.timer = window.setInterval(this.updateProgressData, 1500);
    },
    stopPolling: function (secondsDelay = 1500) {
      setTimeout(() => {
        clearInterval(this.timer);
        this.timer = undefined;
      }, secondsDelay);
    },
  },
  computed: {
    successWithFallback: function () {
      return this.success
        ? this.success
        : `Het ${this.trackerDescription.toLowerCase()} is voltooid.`;
    },
    abortedMessage: function () {
      console.log()
      if (this.feedback_message) {
        return this.feedback_message;
      }
      return `Er is iets misgegaan tijdens het ${this.trackerDescription.toLowerCase()}.`;
    },
  },
  watch: {
    aborted: function () {
      if (this.aborted) {
        this.stopPolling();
      }
    },
    error: function (newVal: string) {
      if (!newVal) {
        return;
      }
      if (newVal === "ECONNABORTED") {
        this.timeOutError = true;
        return;
      }
      this.stopPolling();
    },
    completed: function (newVal: boolean) {
      if (newVal) {
        this.stopPolling();
        this.$emit("completed");
      }
    },
  },
  beforeMount() {
    this.startPolling()
  },
  beforeDestroy() {
    this.stopPolling();
  },
});
