





















import Vue from "vue";
import { StepStatus } from "@/types/progress";

export default Vue.extend({
  name: "progress-step",
  props: {
    description: {
      type: String,
      required: true,
    },
    status: {
      type: Number as () => StepStatus,
      required: true,
    },
    index: {
      type: Number,
      required: false,
    },
    error: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      statusAttrs: {
        [StepStatus.PENDING]: {
          icon: "mdi-minus",
          iconColor: "grey",
          text: "",
          textColor: "grey--text",
        },
        [StepStatus.IN_PROGRESS]: {
          icon: "circular-progress",
          iconColor: "grey--text text--darken-1",
          text: "In behandeling",
          textColor: "font-weight-bold",
        },
        [StepStatus.COMPLETED]: {
          icon: "mdi-check-circle",
          iconColor: "green",
          text: "Voltooid",
          textColor: "",
        },
      },
    };
  },
  computed: {
    icon(): string {
      if (this.error && this.status === StepStatus.IN_PROGRESS) {
        return "mdi-alert-circle";
      }
      return this.statusAttrs[this.status].icon;
    },
    textColorClass(): string {
      return this.statusAttrs[this.status].textColor;
    },
    statusText(): string {
      if (this.error && this.status === StepStatus.IN_PROGRESS) {
        return "Mislukt";
      }
      return this.statusAttrs[this.status].text;
    },
    iconColor(): string {
      if (this.error && this.status === StepStatus.IN_PROGRESS) {
        return "red";
      }
      return this.statusAttrs[this.status].iconColor;
    },
    title(): string {
      return this.index
        ? `${this.index}. ${this.description}`
        : this.description;
    },
  },
});
