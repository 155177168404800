




































































































































































































































import Vue from "vue";
import { UserCreated, UserRetrieved } from "@/types/user";
import SelectOrganization from "@/components/SelectOrganization.vue";


const states = {
  idle: "idle",
  loading: "loading",
  loaded: "loaded",
  failed: "failed",
};

export default Vue.extend({
  name: "RegisterUserComponent",
  components: { SelectOrganization },
  props: {
    itemId: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    newUserCredentials: {
      email: "",
      password: "",
      repeatPassword: "",
      departments: "",
      contactOrgIds: [] as number[],
      organizationIds: [] as number[],
      admin: false,
    },
    dialog: {
      confirmAdminRole: false,
      resetTotp: false
    },
    contactPersonClicked: false,
    valid: false,
    state: "idle",
    error: undefined,
    detail: [],
    states,
    token: "",
    headers: "Content-Type=application/x-www-form-urlencoded",
    showPwdEditFields: false,
  }),
  methods: {
    submit() {
      if (this.itemId) {
        this.editUser();
      } else {
        this.CreateUser();
      }
    },
    resetTotp(){
      this.dialog['resetTotp'] = false
      console.log('not implemented')
      this.$request
        .put(`${this.$store.state.APIurl}/user/reset-totp?user_id=${this.itemId}`, {
          id: this.itemId,
        })
        .then((response: any) => {
          this.state = "loaded";
          this.$emit(
            "success",
            response.data
          );
        })
        .catch((error: any) => {
          this.detail = error.response.data.detail;
          this.state = "failed";
          this.error = error;
          this.$emit("error", this.detail);
        });
    },
    CreateUser() {
      this.state = "loading";
      this.error = undefined;
      this.$request
        .post(`${this.$store.state.APIurl}/user/create`, {
          email: this.newUserCredentials.email,
          password: this.newUserCredentials.password,
          repeated_password: this.newUserCredentials.repeatPassword,
          departments: this.newUserCredentials.departments,
          organization_ids: this.newUserCredentials.organizationIds,
          contact_org_ids: this.newUserCredentials.contactOrgIds,
          admin: `${this.newUserCredentials.admin}`,
        })
        .then((response: any) => {
          const response_data: UserCreated =
            response.data as unknown as UserCreated;
          this.state = "loaded";
          this.$emit(
            "success",
            `Een nieuwe gebruiker met e-mailadres '${response_data.email}' is aangemaakt.`
          );
        })
        .catch((error: any) => {
          this.detail = error.response.data.detail;
          this.state = "failed";
          this.error = error;
          this.$emit("error", this.detail);
        });
    },
    toggleAdmin() {
      this.newUserCredentials.admin = !this.newUserCredentials.admin;
      this.dialog["confirmAdminRole"] = false;
    },
    prefillFormForEdit() {
      this.$request
        .get(`${this.$store.state.APIurl}/user/?user_id=${this.itemId}`)
        .then((response: any) => {
          const response_data: UserRetrieved =
            response.data as unknown as UserRetrieved;
          this.newUserCredentials.email = response_data.email;
          this.newUserCredentials.admin = response_data.admin;
          this.newUserCredentials.organizationIds =
            response_data.organization_ids;
          this.newUserCredentials.contactOrgIds = response_data.contact_org_ids;
          if (this.newUserCredentials.contactOrgIds.length > 0) {
            this.contactPersonClicked = true;
          }
        })
        .catch((error: any) => {
          this.detail = error.response.data.detail;
          this.state = "failed";
          this.error = error;
        });
    },
    editUser() {
      this.$request
        .put(`${this.$store.state.APIurl}/user/update?user_id=${this.itemId}`, {
          id: this.itemId,
          email: this.newUserCredentials.email,
          password: this.newUserCredentials.password,
          repeated_password: this.newUserCredentials.repeatPassword,
          organization_ids: this.newUserCredentials.organizationIds,
          contact_org_ids: this.newUserCredentials.contactOrgIds,
          admin: `${this.newUserCredentials.admin}`,
        })
        .then((response: any) => {
          const response_data: UserCreated =
            response.data as unknown as UserCreated;
          this.state = "loaded";
          this.$emit(
            "success",
            `Gebruiker met e-mailadres '${response_data.email}' is aangepast.`
          );
        })
        .catch((error: any) => {
          this.detail = error.response.data.detail;
          this.state = "failed";
          this.error = error;
          this.$emit("error", this.detail);
        });
    },
  },
  computed: {
    title(): string {
      return this.itemId ? "Account bewerken" : "Registreer een gebruiker";
    },
  },
  watch: {
    contactPersonClicked(switchOn) {
      if (!switchOn) {
        this.newUserCredentials.contactOrgIds = [];
      }
    },
  },
  mounted() {
    // this.existingId = parseInt(this.$route.params.id)
    if (this.itemId) {
      this.prefillFormForEdit();
    }
  },
});
